.title_normal {
  @apply flex flex-col items-center justify-center mx-auto text-center mb-10 md:mb-8 w-full text-xana-txt-title;

  & h2 {
    @apply max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-2xl 2xl:max-w-2xl mb-[20px] md:mb-[20px] font-FreizeitTrial font-medium;
  }

  & p {
    @apply max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-xl 2xl:max-w-2xl mb-[24px] font-AvanttTrial font-[400];
  }
}

.title_big {
  @apply mx-auto mb-20 md:mb-10 w-[100%] sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[55%];

  & span {
    @apply capitalize font-semibold text-xana-purple text-base md:text-sm lg:text-base;
  }
}

.title_text_left {
  @apply text-left mb-10 w-[100%] sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[55%];
}

.title_content {
  @apply flex flex-col items-center justify-center mx-auto text-center mb-10 md:mb-8 w-full text-xana-txt-title;

  & h1 {
    @apply max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-2xl 2xl:max-w-2xl mb-[20px] md:mb-[20px] font-FreizeitTrial font-medium text-xana-txt-title leading-snug;
  }

  .subtitle_section {
    @apply text-xana-txt-date-gray font-semibold text-base md:text-sm lg:text-base flex flex-row items-center mb-5;

    & .svg_separator {
      @apply text-xana-txt-date-gray w-5 h-5 md:w-5 md:h-5 lg:w-5 lg:h-5;
    }
    & span {
      @apply text-xana-txt-date-gray;
    }
  }

  & p {
    @apply max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-xl 2xl:max-w-2xl mb-[24px] font-AvanttTrial font-[400];
  }
}
