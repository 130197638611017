.news_container {
  @apply w-full h-full flex flex-1 flex-col;

  & figure {
    & img {
      @apply w-full h-auto object-cover;
    }
  }

  & .summary {
    & .header_section {
      @apply h-full;

      & .header_section_content {
        @apply flex flex-row items-center;
        & h5 {
          @apply capitalize;
        }
        & .svg_separator {
          @apply w-5 h-5 md:w-5 md:h-5 lg:w-5 lg:h-5;
        }
      }

      & h3 {
        @apply text-left text-xl md:text-xl lg:text-2xl text-xana-txt-title font-medium font-FreizeitTrial normal-case leading-6 md:leading-6 lg:!leading-tight;
      }

      & p {
        @apply text-left font-AvanttTrial font-normal text-xana-txt-news;
      }
    }

    & .footer_section {
      @apply w-full flex flex-col;

      & h5 {
        @apply text-xana-txt-date-gray  text-sm;
      }
    }
  }
}

.section_grid_3_container {
  @apply grid grid-cols-1 gap-6 md:gap-4 lg:gap-4 md:grid-cols-12 rounded-lg;

  & .news3_1_content {
    @apply md:col-span-8 md:row-span-2 rounded-lg transition-all duration-500 ease-in-out transform hover:shadow-lg h-auto w-full border-0 border-transparent;
  }

  & .news3_2_content {
    @apply md:col-span-4 md:row-span-1 rounded-lg transition-all duration-500 ease-in-out transform hover:shadow-lg w-full border-0  border-transparent;
  }
}

.news_card {
  @apply flex w-full h-full overflow-hidden bg-white border-2 rounded-lg border-slate-200;

  & img {
    @apply w-full h-full object-cover object-top rounded-t-lg;
  }

  & .news_card_text_container {
    @apply flex flex-col flex-1 w-full justify-between;

    & h4 {
      @apply font-medium font-AvanttTrial capitalize text-xana-txt-date-blue md:text-base lg:text-base;
    }

    & h2 {
      @apply text-left text-xana-txt-title font-medium font-FreizeitTrial normal-case leading-6 md:leading-8 lg:!leading-tight text-lg md:text-xl mt-0 line-clamp-2;
    }

    & p {
      @apply mt-3 text-base font-normal text-left font-AvanttTrial text-xana-txt-footer md:text-base lg:text-base;
    }

    & footer {
      @apply flex items-center justify-between mt-6;

      & span {
        @apply flex text-base font-semibold text-xana-txt-date-gray;
      }
    }
  }
}

.news_category {
  @apply w-full h-auto mt-0 md:mt-[26px] lg:mt-[56px];

  & .section_news_category {
    @apply w-full md:w-auto;

    & header {
      @apply w-full flex flex-row items-center justify-between border-b-2 border-b-slate-100 -mb-5 md:mb-0;
    }

    & h2 {
      @apply capitalize font-medium text-xana-txt-title text-lg md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl font-FreizeitTrial;
    }
  }
}

.section_banner_detail {
  @apply mt-0 md:mt-0 flex justify-center items-center w-full;

  & figure {
    @apply animate-fade relative overflow-hidden w-full h-full;
    & img {
      @apply object-cover object-top md:object-top lg:object-top xl:object-top 2xl:object-top h-[300px] sm:h-[390px] md:h-[490px] lg:h-[540px] xl:h-[590px] 2xl:h-[640px] min-w-max md:w-full;
    }
  }
}
.blocksrenderer {
  @apply py-10 md:py-14 px-0 sm:px-16 md:px-16 lg:px-16 xl:px-24 2xl:px-32;
  & h1 {
    @apply font-[500] leading-[28px] md:leading-[28px] lg:leading-[32px] xl:leading-[42px] 2xl:leading-[42px] text-xana-txt-title text-3xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-5xl font-FreizeitTrial mt-10 mb-4;
  }
  & h2 {
    @apply font-[500] leading-[28px] md:leading-[28px] lg:leading-[32px] xl:leading-[42px] text-xana-txt-title text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-FreizeitTrial mt-10 mb-4;
  }
  & h3 {
    @apply font-[500] leading-[28px] md:leading-[28px] lg:leading-[32px] xl:leading-[42px] text-xana-txt-title text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-3xl font-FreizeitTrial mt-10 mb-4;
  }
  & h4 {
    @apply font-[500] leading-[28px] md:leading-[28px] lg:leading-[32px] xl:leading-[42px] text-xana-txt-title text-lg sm:text-lg md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl font-FreizeitTrial mt-10 mb-4;
  }

  & h5 {
    @apply font-[500] leading-[28px] md:leading-[28px] lg:leading-[32px] xl:leading-[42px] text-xana-txt-title text-base sm:text-base md:text-lg lg:text-lg xl:text-xl 2xl:text-xl font-FreizeitTrial mt-10 mb-4;
  }

  & h6 {
    @apply font-[500] leading-[28px] md:leading-[28px] lg:leading-[32px] xl:leading-[42px] text-xana-txt-title text-base sm:text-base md:text-base lg:text-base xl:text-lg 2xl:text-lg font-FreizeitTrial mt-10 mb-4;
  }

  & blockquote {
    @apply pl-3 my-8 border-l-[2px] border-l-xana-txt-breadcrumb-blue font-FreizeitTrial text-xana-txt-title font-[500] italic text-lg sm:text-lg md:text-lg lg:text-xl xl:text-xl 2xl:text-xl;
  }

  & ol {
    @apply font-AvanttTrial list-decimal pl-7 space-y-2;

    & li {
      @apply leading-[28px] font-[400] text-base md:text-base lg:text-lg xl:text-lg 2xl:text-lg;
    }
  }

  & ul {
    @apply font-AvanttTrial list-disc pl-5 space-y-2;

    & li {
      @apply leading-[28px] font-[400] text-base md:text-base lg:text-lg xl:text-lg 2xl:text-lg;
    }
  }

  & p {
    @apply font-AvanttTrial text-xana-txt-title font-[300]  text-base md:text-base lg:text-lg xl:text-lg 2xl:text-lg my-4;
  }

  & a {
    @apply text-xana-txt-link text-base md:text-base lg:text-lg xl:text-lg 2xl:text-lg;
  }

  & img {
    @apply mx-auto object-cover w-full overflow-hidden h-full;
  }
}
