.section_tabs {
  @apply flex flex-col items-center justify-center px-0 text-center -mt-5 md:mt-0 w-auto mx-auto;
  & nav {
    @apply flex justify-center p-2 text-center -mt-5 md:mt-0 md:w-auto;
    & ul {
      @apply flex justify-center items-center mx-auto border-b-[1px]  space-x-0 font-AvanttTrial;

      & li {
        @apply sm:px-[42px] md:px-[62px] py-1 text-lg md:text-xl font-normal capitalize cursor-pointer text-xana-txt-text leading-[30px];
      }
    }
  }
}

.section_tabs_content {
  @apply flex-col justify-center items-center w-full mt-3 px-0 lg:px-0;
}
