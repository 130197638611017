.button_address_container {
  @apply w-full md:w-auto flex flex-row justify-start items-center space-x-1 mt-3 transition-all duration-300 ease-in-out transform;

  & span {
    @apply no-underline text-xana-txt-link text-base md:text-base lg:text-base font-medium font-AvanttTrial;
  }

  & svg {
    @apply mt-1 text-xana-txt-link w-6 h-6 md:w-6 md:h-6 lg:w-6 lg:h-6;
  }
}

.button_news_container {
  @apply flex flex-row justify-center mx-auto items-center p-3 py-2 mt-10  text-xana-txt-link hover:bg-xana-blue rounded-lg space-x-3 w-full md:w-auto transition-all duration-300 ease-in-out transform hover:px-7;

  & span {
    @apply no-underline text-xana-txt-link text-base md:text-base lg:text-base font-medium font-AvanttTrial;
  }

  & .icon_svg {
    @apply mt-1 text-xana-txt-link w-8 h-8 md:w-8 md:h-8 lg:w-8 lg:h-8;
  }
}

.button_func_container {
  @apply flex flex-row justify-center mx-auto items-center p-3 py-2 mt-10  text-xana-txt-link bg-xana-blue rounded-lg space-x-3 w-full md:w-auto transition-all duration-300 ease-in-out transform hover:px-7;

  & span {
    @apply no-underline text-xana-txt-link text-base md:text-base lg:text-base font-medium font-AvanttTrial;
  }

  & .icon_svg {
    @apply text-xana-txt-border w-5 h-5 md:w-5 md:h-5 lg:w-5 lg:h-5;
  }
}
