.section_aboutus {
  @apply w-full h-auto mx-auto mt-[10px];

  & .aboutus_content_container {
    @apply px-0 mt-12 sm:mt-24 sm:px-0;

    & article {
      @apply grid h-auto grid-cols-1 grid-rows-1 gap-6 sm:gap-0 sm:grid-cols-12 py-6;

      & .article_text_container {
        @apply flex flex-col items-center justify-center sm:items-start sm:justify-center w-full h-full col-span-full sm:col-span-8 mb-0 sm:mb-0;

        & h3 {
          @apply mb-0 lg:mb-3 text-center sm:text-left font-FreizeitTrial leading-[38px] font-[500] sm:text-2xl lg:text-[30px];
        }
        & p {
          @apply text-base sm:text-[16px] lg:text-[18px] text-center sm:text-left font-AvanttTrial leading-[28px] font-[300] text-xana-txt-text;
        }
      }

      & .article_figure_container {
        @apply flex flex-row items-center w-full col-span-full sm:col-span-4 h-auto sm:h-full mb-14 sm:mb-0;

        & img {
          @apply object-contain sm:object-cover object-left-top w-[90%] sm:w-auto h-full sm:h-[200px] md:h-[220px] lg:h-[230px] xl:h-[260px];
        }
      }
    }
  }
}
