.section_features {
  @apply flex flex-wrap -mt-10 md:mt-0 space-y-10 md:space-y-0;
}

.features_container {
  @apply flex flex-col items-center justify-start w-full md:px-4 md:w-1/3;

  & .img_image_container {
    @apply w-[40%] md:w-[60%] h-auto md:h-[180px] lg:h-[190px] xl:h-[200px] 2xl:h-[300px];
  }

  & .img_icon_svg_container {
    @apply w-[10%] md:w-[15%] h-auto md:h-[40px] lg:h-[45px] xl:h-[50px] 2xl:h-[100px];
  }

  & section {
    @apply w-[80%] md:w-full md:mt-0;
    & h2 {
      @apply mb-2 text-lg md:text-base xl:text-lg font-bold text-xana-txt-title;
    }
    & p {
      @apply text-sm md:text-xs lg:text-base font-normal text-xana-txt-text;
    }
  }
}

.section_features_faqs {
  @apply text-left flex flex-col items-start justify-start w-full;

  & h3 {
    @apply mb-2 text-lg md:text-base xl:text-lg font-bold text-xana-txt-title;
  }

  & p {
    @apply text-sm md:text-xs lg:text-base font-normal text-xana-txt-text;
  }
}


.section_features_content {
  @apply mx-auto md:grid max-w-[100%] md:grid-flow-col-dense md:grid-cols-2 mt-10 md:mt-0 lg:gap-14 md:px-2 lg:px-8;

  & .text_feature_content {
    @apply mx-auto max-w-xl px-2 lg:mx-0 md:max-w-none py-0 md:py-16 md:px-0;

    & .text_feature_content_container {
      @apply text-left md:mt-6;

      & h2 {
        @apply mb-2 text-xl font-medium md:text-xl lg:text-2xl  text-xana-txt-title;
      }

      & p {
        @apply text-base font-normal md:text-base lg:text-lg text-xana-txt-text;
      }
    }
  }

  & .image_feature_content {
    @apply flex items-center justify-center mt-5 md:mt-0;

    & figure {
      @apply flex items-center justify-center md:h-auto  w-full px-2;

      & img {
        @apply object-contain object-center md:object-cover md:object-left w-full md:w-auto h-[330px] md:h-[270px] lg:md:h-[300px];
      }
    }
  }
}
