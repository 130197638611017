.main_container {
  @apply flex flex-col items-center justify-start min-h-screen w-full;

  & article {
    @apply w-full mx-auto;

    .section_content {
      @apply relative overflow-hidden py-5 text-center;
    }
  }
}

.form_element {
  @apply h-full p-[8px] w-full shadow-sm rounded-lg border-2 focus:ring-2 focus:ring-xana-button-border border-gray-300 bg-transparent text-gray-400 placeholder:text-slate-300 text-base focus:border-transparent appearance-none;
}

.section_load_more_news {
  @apply flex justify-center w-full mt-10;
}
