.section_faqs {
  @apply w-full px-0  md:px-20 lg:px-32 xl:px-40;

  & li {
    @apply border-b;

    & button {
      @apply relative flex items-center w-full py-5 text-left focus:outline-none;
      & h6 {
        @apply flex-1 text-lg font-medium  text-xana-txt-title;
      }
      & .svg_container {
        @apply flex-shrink-0 w-6 h-6 ml-auto text-xana-icon-gray;
      }
    }

    & p {
      @apply pb-5 space-y-2 text-base leading-relaxed text-xana-txt-accordion;
    }
  }
}
