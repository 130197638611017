.section_banner_lef_bottom_container {
  @apply mt-20 md:mt-0 flex flex-col-reverse md:flex-col justify-end items-center w-full h-auto bg-transparent;

  & footer {
    @apply md:absolute flex flex-col items-center md:items-start justify-center px-3 sm:px-8 md:px-0 w-full sm:max-w-[98%] md:max-w-[90%] lg:max-w-[90%] xl:max-w-[90%] 2xl:max-w-[90%] animate-fade animate-delay-200 animate-duration-[500ms] animate-ease-in-out;

    & header {
      @apply max-w-sm sm:max-w-md md:max-w-sm lg:max-w-sm xl:max-w-sm 2xl:max-w-lg md:break-words;

      & h1 {
        @apply text-4xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-5xl leading-tight text-center md:text-start mb-5 sm:mb-5 md:mb-4 xl:mb-8;
      }

      & p {
        @apply text-base text-xana-txt-banner_gray md:text-base lg:text-xl;
      }
    }
  }

  & figure {
    @apply animate-fade;
    & img {
      @apply w-screen object-cover object-left md:object-left-top lg:md:object-center h-[350px] sm:h-[450px] md:h-[550px] lg:h-[550px] xl:h-[600px] 2xl:h-[700px];
    }
  }
}

.section_banner_lef_center_container {
  @apply mt-5 md:mt-6 flex flex-col-reverse md:flex-col justify-end md:justify-center items-center w-full h-auto bg-transparent;

  & footer {
    @apply absolute flex flex-col items-center md:items-start justify-start md:justify-center animate-fade animate-delay-200 animate-duration-[500ms] animate-ease-in-out;

    & header {
      @apply mt-5 md:mt-0 max-w-[100%] sm:max-w-[100%] md:max-w-[45%] lg:max-w-[43%] xl:max-w-[40%] 2xl:max-w-[45%];

      & h2 {
        @apply text-4xl sm:text-5xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-7xl leading-[45px] sm:leading-[50px] md:leading-[50px] lg:leading-[60px] xl:!leading-[70px] 2xl:!leading-[75px] text-center md:text-left mb-5 sm:mb-5 md:mb-4 xl:mb-4 mx-auto md:mx-0 max-w-[80%] sm:max-w-[100%] md:w-full font-FreizeitTrial font-[400]  text-xana-txt-banner_blue;
      }

      & h1 {
        @apply text-center md:text-left text-xl md:text-xl lg:text-2xl xl:text-2xl max-w-[100%] md:max-w-[85%] lg:max-w-[78%] xl:max-w-[82%] text-xana-txt-banner_gray font-[380] leading-normal  md:leading-normal;
      }
    }
  }

  & figure {
    @apply animate-fade relative overflow-hidden w-full h-[630px] sm:h-[900px] md:h-[550px] lg:h-[600px] xl:h-[750px] 2xl:h-[780px];
    & img {
      @apply object-cover object-bottom md:object-left-bottom lg:object-right-bottom xl:object-right-bottom 2xl:object-right-bottom w-full h-full;
    }
  }
}

.section_banner_lef_center_without_text_container {
  @apply mt-12 md:mt-14 flex flex-col-reverse md:flex-col justify-end md:justify-center items-center w-full h-auto bg-transparent;

  & footer {
    @apply absolute flex flex-col items-center md:items-start justify-start md:justify-center px-3 sm:px-8 md:px-0 md:h-auto w-full sm:max-w-[98%] md:max-w-[90%] lg:max-w-[90%] xl:max-w-[90%] 2xl:max-w-[90%] animate-fade animate-delay-200 animate-duration-[500ms] animate-ease-in-out;

    & header {
      @apply mt-5 md:mt-0 max-w-[100%] sm:max-w-[100%] md:max-w-[45%] lg:max-w-[38%] xl:max-w-lg 2xl:max-w-lg;

      & h1 {
        @apply text-4xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-7xl md:leading-[60px] lg:leading-[60px] xl:!leading-[70px] 2xl:!leading-[80px] text-center md:text-left mb-5 sm:mb-5 md:mb-4 xl:mb-8 mx-auto md:mx-0 max-w-[80%] sm:max-w-[100%] md:w-full;
      }

      & p {
        @apply text-center md:text-left text-xana-txt-banner_gray text-xl md:text-xl lg:text-xl xl:text-xl max-w-[100%] md:max-w-[85%] lg:max-w-[78%] xl:max-w-[82%];
      }
    }
  }

  & figure {
    @apply animate-fade relative overflow-hidden w-full h-full;
    & img {
      @apply object-cover object-bottom md:object-right-bottom lg:object-right-bottom xl:object-right-bottom 2xl:object-right-bottom h-[700px] sm:h-[700px] md:h-[550px] lg:h-[500px] xl:h-[550px] 2xl:h-[600px] min-w-max md:w-full -translate-x-[45%] sm:-translate-x-[38%] md:translate-x-0;

      /* @apply object-cover object-top  md:object-cover w-screen h-fit sm:h-[350px] md:h-[400px] lg:h-[500px]  xl:h-[570px] 2xl:h-[650px]; */
    }
  }
}
