.section_text_address {
  @apply w-full mx-auto mt-6 md:mt-10 lg:px-0 animate-fade-up animate-delay-300 animate-duration-[600ms] animate-ease-in-out;

  & .address_grid_container {
    @apply grid grid-cols-1 gap-6 md:gap-8 md:gap-y-14 lg:gap-8 lg:gap-y-14 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 w-full mb-12;
  }
}

.section_address_map {
  @apply text-left flex flex-col items-start justify-start pb-6 md:pb-0 border-b-2 border-b-slate-100 md:border-none w-full;

  & h4 {
    @apply mb-1;
  }

  & .section_address_text {
    @apply flex flex-col items-start justify-start w-full h-full font-FreizeitTrial;

    & .address_text {
      @apply mb-0 no-underline text-base md:text-base lg:text-base font-medium text-xana-txt-address leading-[24px] font-FreizeitTrial;
    }
    & .address_ref_text {
      @apply mt-2 no-underline text-xana-txt-text text-base md:text-base lg:text-base italic font-[400] font-AvanttTrial;
    }
  }

  & .schedule_container {
    @apply flex flex-row justify-start items-center w-full mt-2 font-AvanttTrial;

    & .schedule_items_content {
      @apply w-auto flex flex-col;

      & .schedule_label {
        @apply text-xana-txt-text text-base md:text-base lg:text-base font-semibold mr-1;
      }

      & .schedule_time {
        @apply flex text-xana-txt-text text-base md:text-base lg:text-base font-[400] font-AvanttTrial;

        & div > span > strong {
          @apply text-xana-txt-text text-base md:text-base lg:text-base font-[500] leading-[24px];
        }
      }
    }

    & .separator {
      @apply text-xana-txt-text text-base md:text-base lg:text-base font-medium px-1;
    }
  }

  & .phone_container {
    @apply flex flex-row justify-start items-center space-x-2 font-AvanttTrial font-[500] leading-[24px];

    & .span_label {
      @apply text-xana-txt-text text-base md:text-base lg:text-base font-normal;
    }

    & .span_phone {
      @apply text-xana-txt-phone text-base md:text-base lg:text-base font-[500];
    }
  }
}
